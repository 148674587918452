/**
 * Jotem
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Drawer Controller.
 */
export default class extends Controller {
  static targets = ['drawer', 'title', 'subject', 'content', 'file'];

  /**
   * Open drawer.
   *
   * @param {Event} e
   */
  async open(e) {
    const { title, form, fileId } = e.params;
    const type = form ?? 'contact';

    const response = await fetch(`/forms/${type}`);
    this.contentTarget.innerHTML = await response.text();

    // Wait for drawer content to be rendered, then show
    setTimeout(() => {
      if (this.hasFileTarget && fileId) this.fileTarget.value = fileId;
      this.titleTarget.innerHTML = title;
      this.subjectTarget.value = title;
      this.drawerTarget.classList.add('active');
    }, 0);
  }

  /**
   * Close drawer.
   */
  close() {
    this.drawerTarget.classList.remove('active');
  }
}
